import SectionTitle from "./sectiontitle"
import Card from "./card"
import React from "react"
import styled from "styled-components"
import { StaticQuery, graphql } from "gatsby"

let gridString = ''
for (let i = 0; i < 102; i++) {
  let index = i + 1;
  let column = i % 3 + 1;
  let row = Math.floor(i / 3) + 1;
  gridString+=`
    > div:nth-child(${index}) {
    -ms-grid-column: ${column};
    -ms-grid-row: ${row};
  }
`
}

const CardContainer = styled.div`
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(335px, 1fr));
  -ms-grid-columns: auto auto auto;
  -ms-grid-rows: auto auto auto auto;
  row-gap: 16px;
  justify-items: center;
  margin-top: 16px;
  margin-left: 0;

  ${gridString}
  > div:nth-child(n + 103) {
    display: none;
  }
  
`;

const Articles = props => {

  if (!props.data) return null

  return <StaticQuery
    query={graphql`
      query {
        pagesYaml {
          default_tag_color
          tag_colors {
            label
            color
          }
        }
      }
    `}
    render={data => {
      return (
        <div>
          {(() => {
            if(props.title) {
              return <SectionTitle value={props.title} />
            }
          })()}
          <CardContainer>
            {props.data.edges.map(({ node }) => (
              <Card key={node.fields.slug}
                    title={node.fields.title}
                    text={node.fields.summary}
                    image={node.fields.thumbnail.childImageSharp}
                    tag={{
                      tags: node.fields.tags,
                      default_color: data.pagesYaml.default_tag_color,
                      colors: data.pagesYaml.tag_colors}}
                    link={node.fields.slug}
                    date={node.fields.date}
                    author={node.fields.author}
              />
            ))}
          </CardContainer>
        </div>
      )
    }} />
}

Articles.defaultProps = {
  title: "一覧",
}

export default Articles