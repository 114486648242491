import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";

const CardBase = styled.div`
  width: 320px;
  height: 420px;
  /* background-color: lightgrey; */
  position: relative;
  overflow: hidden;
  display: grid;
  grid-template-columns: 8px 1fr 8px;
  grid-template-rows: 250px 70px 100px;

  @media (min-width: 700px) {
    height: 400px;
    grid-template-rows: 250px 150px;
  }

  .holder {
    grid-column: 2/3;
    grid-row: 1/2;
    overflow: hidden;
    height: 240px;
    background-color: offwhite;
  }

  .textholder {
    grid-column: 2/3;
    grid-row: 2/3;
    height: 48px;
    .tagbox {
      display: none;
      @media (min-width: 700px) {
        display: block;
      }
    }
    p {
      font-size: 60%;
      color: #aaa;
      text-align: right;
      margin-bottom: 0;
      line-height: 1.5em;
      a {
        color: #aaa;
      }
    }
  }

  .holder img {
    object-fit: cover;
    height: 120%;
  }

  h3 {
    font-size: 15px;
    margin-bottom: 0;
    height: 3em;
  }

  .mobile {
    grid-column: 2/3;
    grid-row: 3/4;
    p {
      font-size: 12px;
      line-height: 1.4em;
      letter-spacing: 0.3px;
    }
    .tagbox {
    }
    @media (min-width: 700px) {
      display: none;
      font-size: 13px;
    }
  }

  a {
    text-decoration: none;
    color: black;
  }

  span {
    font-size: 11px;
    border-radius: 3px;
    // background: teal;
    background: #75A9FF;
    color: white;
    margin: 0 8px 0 0;
    border: none;
    cursor: pointer;
    padding: 6px 10px;
  }

  .off {
    display: none;
  }
`;

const Tags = props => {
  if (props.tag.tags) {
    return props.tag.tags.map(function (tag, i) {
      let tagObj = props.tag.colors.find((item) => {
        return item.label === tag
      })
      let tagColor = tagObj ? tagObj.color : props.tag.default_color
      return (
        <Link to={"/tag/" + tag} key={i}>
          <span style={{backgroundColor: tagColor}}>{tag}</span>
        </Link>
      )
    })
  }
  return false
}

const Card = props => (
  <CardBase>
    <Link to={props.link} className="holder">
      <Img
        style={{ borderRadius: "10px" }}
        fixed={props.image.fixed} alt={props.alt} />
    </Link>
    <div className="textholder">
      <Link to={props.link}>
        <h3>{props.title}</h3>
      </Link>
      <p>
        {props.date}<br/>
        <Link to={'/author/'+props.author}>
          {props.author}
        </Link>
      </p>
      <div className="tagbox">
        <Tags tag={props.tag}/>
      </div>
    </div>
    <div className="mobile">
      {(() => {
        if (props.text)
          return <p>{props.text}</p>
      })()}
      <div className="tagbox">
        <Tags tag={props.tag}/>
      </div>
    </div>

  </CardBase>
);

Card.defaultProps = {
  tag: {},
  link: "/"
}

export default Card;
