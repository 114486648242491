import React from "react";
import styled from "styled-components";
import { Link } from "gatsby"

const BannerContainer = styled.div`
  display: grid;
//  grid-template-columns: 1fr 1fr;
  grid-template-columns: 0.6fr;
  grid-column-gap: 40px;
  grid-row-gap: 16px;
  justify-content: center;
  justify-items: center;
  margin: 40px 80px 50px 80px;
  .banner {
    img {
      border-radius: 10px;
      margin-bottom: 0;
      vertical-align: bottom;
    }
  }
  .banner:nth-child(odd) {
    justify-self: right;
    @media (max-width: 700px) {
      justify-self: center;
    }
  }
  .banner:nth-child(even) {
    justify-self: left;
    @media (max-width: 700px) {
      justify-self: center;
    }
  }
  @media (max-width: 700px) {
    grid-template-columns: 1fr;
    margin: 40px 24px;
  }
`

const BannerDouble = props => (
  <BannerContainer>
    <div className="banner">
      <Link to="/series/workingholiday"><img src={require("../images/banner/workingholiday.jpg")} alt="" /></Link>
    </div>
    <div className="banner">
      <Link to="/series/ehon"><img src={require("../images/banner/ehon.jpg")} alt="" /></Link>
    </div>
  </BannerContainer>
)

const Banner = props => (
  <BannerContainer>
    <div className="banner">
      <Link to="/series/cafeaulait"><img src={require("../images/banner/cafeaulait.jpg")} alt="" /></Link>
    </div>
  </BannerContainer>
)

export default Banner;